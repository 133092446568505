body {
  margin: 0;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


Main {
  min-height: 100%;
  margin: 0 auto -60px;
}

.footer{
  margin-top: 5rem;
  padding: 1rem 3rem;
  height: 60px;
  background-color: #2a363b;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: right;
}

p.foot {
  display: inline;
  color: #fffcdd;
  font-family: september, sans-serif;
}

span.footl {
  color: #fffcdd;
  font-family: september, sans-serif;
  float: left;
}

div.drop {
   margin: 2%;
   text-align: left;
 }

div.left {
   text-align: left;
 }

div.sectionStart {
   text-align: left;
   padding: 0 25px 25px 0;
   margin: 75px 25px 0 2%;
 }

div.Top-Start {
   padding: 0 25px 25px 0;
   margin: 0 25px 0 2%;
 }
