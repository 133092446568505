@import url("https://use.typekit.net/pcd8ehb.css");

:root {
  --white: #ffffff;
  --off: #fffcdd;
  --orange: #fecea8;
  --peach: #ff8c64;
  --pink: #ff847c;
  --grey: #2a363b;
  --red: #d52b21;
}

html, body {
  background-image: linear-gradient(180deg, #faede1 2.38%, transparent 2.38%, transparent 50%, #faede1 50%, #faede1 52.38%, transparent 52.38%, transparent 100%), linear-gradient(90deg, #faede1 2.38%, transparent 2.38%, transparent 50%, #faede1 50%, #faede1 52.38%, transparent 52.38%, transparent 100%);
  background-size: 44.00px 44.00px;
}

.App {
  text-align: center;
  font-family: latex, sans-serif;
  font-weight: 400;
  font-style: normal;
  color: var(--grey);
  word-spacing: 3px;
  padding-top: 10%;
}

@media only screen and (max-width: 768px) {
  .App {
    padding-top: 20%;
  }
}

@media only screen and (max-width: 600px) {
  .App {
    padding-top: 25%;
  }
}

.App-logo {
  height: 12vmin;
  pointer-events: none;
  filter: drop-shadow(3px 3px 0px rgba(42, 54, 59));
}

.svg-con {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  position: absolute;
}

  p.bub {
    position: absolute;
    font-size: 10pt;
    max-width: fit-content;
  }

/* a.bub {
  position: absolute;
  font-size: 13pt;
} */
/* div.contact {
margin-left: 1em;
} */

.App-main {
  min-height: 100vh;
  /* display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; */
  font-size: calc(10px + 2vmin);
  color: #2a363b;
  margin: 0.5em;
}

a.App-link {
  color: var(--pink);
  font-weight: 900;
  font-size: 24pt;
  letter-spacing: 3px;
  text-decoration: none;
}

a.App-link:hover {
  color: #2a363b;
}

div.Icons {
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-content: space-around;
  align-items: flex-start;
  padding: 10px;
}

a.Icons-link {
  color: var(--pink);
  font-weight: 900;
  font-size: 24pt;
  letter-spacing: 3px;
  text-decoration: none;
  /* margin-left: 1em; */
}

p.Icon {
  color: var(--pink);
  font-weight: 900;
  font-size: 24pt;
  letter-spacing: 3px;
  text-decoration: none;
  display: inline;
}

a.Icons-link:hover {
  color: #2a363b;
}

a.Git-link {
  color: var(--pink);

}

a.Git-link:hover {
  color: #2a363b;
}

a.Footer-link {
  color: var(--off);

}

a.Footer-link:hover {
  color: var(--pink);
}

.H1 {
  font-family: september, sans-serif;
  font-weight: 500;
  font-style: normal;
}

p.Profile-Info {
  font-family: september, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 13pt;
  margin-left: 1.7em;
  text-align: left;
  padding-top: 1%;
}

div.Skill-Icons {
  text-align: center;
  margin: auto;
  margin-right: 10%;
  margin-top: 3%;
}

p.Skill-Name {
  font-size: 12pt;
  text-align: center;
}
p.resume {
  font-size: 11pt;
}

a.Icons-Link-Text {
  color: var(--pink);
  font-size: 12pt;
  letter-spacing: 2px;
  text-decoration: none;
}
a.Icons-Link-Text:hover {
  color: var(--grey);
  text-decoration: none;
}

div.skillL {
  margin: 0 25px 0 2%;
}


h1.headp {
  display: inline;
  border-bottom: 4px solid #212529;
  /* margin-bottom: 25px; */
}

div.container-fluid {
  padding-left: 0;
  padding-right: 0;
}

Div.pro-card {
  margin-left: 1em !important;
  max-width: 100% !important;
}


div.container-fluid {
  padding-right: 0;
}

div.row {
  margin-right: 0px;
  margin-left: 0px;
  padding-top: 1%;
}

div.Pad-Icons {
      padding: 1% 0 0 0;
}

div.col-lg-1, div.col-lg-10, div.col-lg-11, div.col-lg-12, div.col-lg-2, div.col-lg-2, div.col-lg-3, div.col-lg-4, div.col-lg-5, div.col-lg-6, div.col-lg-7, div.col-lg-8, div.col-lg-9,
div.col-md-1, div.col-md-10, div.col-md-11, div.col-md-12, div.col-md-2, div.col-md-3, div.col-md-4, div.col-md-5, div.col-md-6, div.col-md-7, div.col-md-8, div.col-md-9,
div.col-sm-1, div.col-sm-10, div.col-sm-11, div.col-sm-12, div.col-sm-2, div.col-sm-3, div.col-sm-4, div.col-sm-5, div.col-sm-6, div.col-sm-7, div.col-sm-8, div.col-sm-9,
div.col-xs-1, div.col-xs-10, div.col-xs-11, div.col-xs-12, div.col-xs-2, div.col-xs-3, div.col-xs-4, div.col-xs-5, div.col-xs-6, div.col-xs-7, div.col-xs-8, div.col-xs-9 {
  padding-left: 0;
  padding-right: 0;
}

h1.head {
  display: inline;
  border-bottom: 4px solid #212529;
}

@media only screen and (max-width: 768px) {
  p.Skill-Name {
    font-size: 12pt;
    text-align: center;
  }
}

@media only screen and (max-width: 600px) {
  p.Skill-Name {
    display: none;
  }
  p.resume {
    display: none;
  }
  div.Icons-Pr {
    margin-left: 1em;
    text-align: left;
  }
  h1.head {
    display: inline;
    border-bottom: 4px solid #212529;
    font-size: 18pt;
  }
}


div.bubble {
  max-width: fit-content;
}

p.Card-About {
  font-family: september, sans-serif;
  font-size: 12pt;
  text-align: left;
}

.card-title{
  color: black !important;
  background: #ffffffc4;
  font-weight: 900;
  font-style: normal;
  font-size: 12pt !important;
}

a.Project-Link-Text {
  color: var(--pink) !important;
  font-size: 16pt;
  letter-spacing: 2px;
  text-decoration: none;
}

a.Project-Link-Text:hover {
  color: var(--grey) !important;
  text-decoration: none;
}

@media only screen and (max-width: 768px) {
  p.re-text {
    font-size: 12pt;
  }
  Div.profile-li {
    text-align: left;
    margin-left: 1em;
  }
}

@media only screen and (max-width: 600px) {
  p.re-text {
    font-size: 10pt;
  }
  Div.profile-li {
    text-align: left;
    margin-left: 2em;
  }
}

.card-pad {
  padding: 2% !important;
}

/* Form */

Button.color {
  background-color: var(--grey);
  color: white;
  float: right;
}
Button:focus.color {
  background-color: var(--pink);
  color: white;
  float: right;
}
Button:hover.color {
  background-color: var(--peach);
  color: white;
  float: right;
}

Div.form-bg{
  background-color: #ffffffbf;
  /* margin: 0 5%;
  padding: 5% 0; */
}
